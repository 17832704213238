import React from 'react'
import { Link } from 'gatsby'
import styles from './dashboard.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ClientsPage = () => (
   <Layout>
      <SEO title="Client Dashboard" />
      <div
         className={[
            'page',
            styles.page__dashboard,
            styles.dashboard__clients,
         ].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Client Dashboard
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               Whether you're looking for a form or trying to view your
               dashboard, this is the place.
            </p>
         </div>
         <div className={styles.dashboard__sections}>
            <div className={styles.section}>
               <div className={styles.section__content}>
                  <h3 className={styles.section__title}>
                     Raymond James Client Access
                  </h3>
                  <p className={styles.section__description}>
                     RJ Client Access provides convenient, secure access to your
                     Raymond James accounts.
                  </p>
               </div>
               <div className={styles.section__actions}>
                  <Link
                     to="/clients/client-access"
                     className="button button--secondary button--rounded button--has-icon">
                     Access Your Accounts{' '}
                     <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
            <div className={styles.section}>
               <div className={styles.section__content}>
                  <h3 className={styles.section__title}>
                     Insights by SPC Financial<sup>&reg;</sup>
                  </h3>
                  <p className={styles.section__description}>
                     Insights provides a "looking glass" into your complete
                     financial picture by organizing all of your financial
                     accounts in one place. It also enables you to track
                     spending, create budgets, and securely store documents.
                  </p>
               </div>
               <div className={styles.section__actions}>
                  <Link
                     to="/clients/insights"
                     className="button button--primary button--rounded button--has-icon">
                     View Your Dashboard{' '}
                     <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
            <div className={styles.section}>
               <div className={styles.section__content}>
                  <h3 className={styles.section__title}>
                     Forms &amp; Documents
                  </h3>
                  <p className={styles.section__description}>
                     We provide an easily accessible collection of informative
                     documents and forms for our clients' convenience and
                     review.
                  </p>
               </div>
               <div className={styles.section__actions}>
                  <Link
                     to="/clients/forms"
                     className="button button--secondary button--rounded button--has-icon">
                     Find a Form <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </div>
         </div>
      </div>
   </Layout>
)

export default ClientsPage
